import { useCallback, useState, useEffect, useMemo } from 'react';
import validateCreditCard from 'card-validator';
import { EMITTERS, EMITTER_VALIDATE } from '../../../../../../app/const/Emitters';
import { useCheckoutFormContext } from '../../../hooks/CheckoutFormContext';
import { checkoutAllValues, ICheckoutAll } from '../../../initialValues';
import { API_URL } from '../../../../../../config';
import { BlackList } from '../../../../../../app/models';

const initial: BlackList = {
  bin_blacklist_id: -1,
  bin: [],
  error_message: '',
};

export const useValidatorCardNumber = () => {
  const [blackList, setBlackList] = useState<BlackList>(initial);
  const { values } = useCheckoutFormContext();

  const valuesForm = useMemo(() => ({ ...checkoutAllValues, ...values }), [values]);

  useEffect(() => {
    if (!valuesForm.paymentMethod.payment.reference || blackList.bin_blacklist_id !== -1) return;
    (async () => {
      const response = await fetch(`${API_URL}/card/blacklist/${valuesForm.paymentMethod.payment.reference}`);
      const result: [BlackList] = await response.json();
      if (!result[0]) return;
      setBlackList(result[0]);
    })();
  }, [valuesForm.paymentMethod.payment.reference, blackList.bin_blacklist_id]);

  const validatorCardNumber = useCallback(
    (values: string = '', allValues: ICheckoutAll) => {
      const isHipotecario = allValues.paymentMethod.emitter.card_emiter_id === EMITTERS.HIPOTECARIO;
      const isHipotecarioDebito = allValues.paymentMethod.card.type.toLocaleLowerCase() === 'debito';
      const bin_length = isHipotecario ? (isHipotecarioDebito ? 6 : 8) : 6;
      if (!values) return 'Completa este campo.';
      if (
        allValues.paymentMethod.emitter.card_emiter_id === EMITTERS.BAPRO ||
        allValues.paymentMethod.emitter.card_emiter_id === EMITTERS.AMEX ||
        allValues.paymentMethod.emitter.card_emiter_id === EMITTERS.NARANJA ||
        allValues.paymentMethod.emitter.card_emiter_id === EMITTERS.BNA ||
        isHipotecario
      ) {
        const _match = !!allValues.paymentMethod.card.match.find((item: string) => item === values.substring(0, bin_length));
        if (!_match) return 'El numero de tarjeta no coincide con el medio de pago seleccionado previamente';
      } else if (values.length) {
        const { isValid, card } = validateCreditCard.number(values);
        if (!isValid) return 'Número de tarjeta inválido.';
        if (card && card.type) {
          if (card.type !== EMITTER_VALIDATE[allValues.paymentMethod.emitter.card_emiter_id])
            return 'El numero de tarjeta no coincide con el medio de pago seleccionado previamente';
        }
      }

      if (blackList.bin.find((_bin) => _bin === values.substring(0, bin_length))) {
        return blackList.error_message;
      }
    },
    [blackList],
  );
  return { validatorCardNumber };
};
