import { CardData, PaymentSimulation } from 'src/app/models';
import { filterInterestRate } from '../helpers';
import { BankNoInterest, BankOptions, PaymentMethodProps } from '../interfaces';

export const useBankOptions = ({ data, bankOptions }: PaymentMethodProps) => {
  const bankMapper: Map<number, { bankOptions: BankOptions[]; bankNoInterest: BankNoInterest[] }> = new Map<
    number,
    { bankOptions: BankOptions[]; bankNoInterest: BankNoInterest[] }
  >();
  const validation24 = ['AHORA 24', 'AHORA 30', '24 CUOTAS FIJAS', 'AHORA 12'];
  const filteredBy24 = data.targets.filter((promo) => validation24.includes(promo));
  // recorro el array con las opciones bancarias, valido que no sean de 24.
  bankOptions.forEach((bank: PaymentSimulation) => {
    if (bank.options) {
      Object.entries(bank.options).forEach(([key, value]) => {
        const formatValue = value as CardData;
        formatValue.payments.forEach((payment) => {
          if (filteredBy24) {
            // guardo en un map los payment y como key uso las cuotas para agrupar los payment que coincidan.
            const validationMapper: any = bankMapper.has(payment.installments)
              ? bankMapper.get(payment.installments)
              : { bankOptions: [], bankNoInterest: [] };

            const object: any = {
              installments: payment.installments,
              amount: Math.round(payment.amount),
              emiter: +key,
              emiter_img_url: formatValue.card.emiter_image_url,
              type: formatValue.card.type,
              card_id: +formatValue.card.card_id,
              targets: filteredBy24,
              discount: payment.total_discount
            };
            // creo un objeto para los payment sin interes.
            if (payment.amount === payment.original_amount) {
              // aqui es donde se guardan;
              bankMapper.set(payment.installments, {
                bankOptions: [...validationMapper.bankOptions],
                bankNoInterest: [...validationMapper.bankNoInterest, object],
              });
              // por descarte entran acá los payment con intereses.
            } else if (Math.sign(payment.amount - payment.original_amount) === 1 || payment.installments === 1) {
              object.interest = Math.round(payment.amount - payment.original_amount);
              // comprabas cual es el que tiene menor interes
              bankMapper.set(payment.installments, {
                bankOptions: [...validationMapper.bankOptions, object],
                bankNoInterest: [...validationMapper.bankNoInterest],
              });
            } else if ((payment.amount + (payment.total_discount ?? 0)) <= payment.original_amount) {
                bankMapper.set(payment.installments, {
                  bankOptions: [...validationMapper.bankOptions],
                  bankNoInterest: [...validationMapper.bankNoInterest, object],
                });
                } else {
                  bankMapper.set(payment.installments, {
                    bankOptions: [...validationMapper.bankOptions, object],
                    bankNoInterest: [...validationMapper.bankNoInterest],
                  });
                }
          }
        });
      });
    }
  });

  // esta función checkea si existe, si existe = comparo con el existente, si no existe = lo seteo
  filterInterestRate(bankMapper);

  // el map lo convierto en array para manejarlo mas fácilmente y lo ordeno de mayor a menor por cantidad de cuotas.
  const sortedMap = [...bankMapper].sort((a: any, b: any) => b[0] - a[0]);
  const options: { installments: number; options: BankNoInterest[] }[] = [];
  const usedNoInterest: number[] = [];
  const usedInterest: number[] = [];

  // configuracion de los destacados a mostrar = default matchea las mejores ya sean sin interes o fijas
  // fijas = cuotas con interes
  // sin interes.
  const destacated: string = data.config_values.bank_config;
  const { card_id, singlePayOption } = data.config_values;

  switch (destacated) {
    case 'Sin Interes':
      sortedMap.forEach((item) => {
        if (item[1].bankNoInterest.length) {
          const object: { installments: number; options: BankNoInterest[] } = {
            installments: item[0],
            options: [],
          };

          item[1].bankNoInterest.forEach((item) => {
            if (!usedNoInterest.find((card) => card === item.card_id)) {
              object.options.push(item);
              usedNoInterest.push(item.card_id);
            }
          });
          options.push(object);
        }
      });
      break;
    case 'Cuotas Fijas':
      sortedMap.forEach((item) => {
        if (item[1].bankOptions.length) {
          const object: { installments: number; options: BankOptions[] } = {
            installments: item[0],
            options: [],
          };

          item[1].bankOptions.forEach((item) => {
            if (!usedInterest.find((card) => card === item.card_id)) {
              object.options.push(item);
              usedInterest.push(item.card_id);
            }
          });
          options.push(object);
        }
      });
      break;
    default:
      sortedMap.forEach((item) => {
        if (item[1].bankNoInterest.length) {
          const object: { installments: number; options: BankNoInterest[] } = {
            installments: item[0],
            options: [],
          };

          item[1].bankNoInterest.forEach((item) => {
            if (!usedNoInterest.find((card) => card === item.card_id)) {
              object.options.push(item);
              usedNoInterest.push(item.card_id);
            }
          });
          options.push(object);
        }
      });

      if (options.length) {
        sortedMap.forEach((item) => {
          if (item[1].bankOptions.length) {
            const object: { installments: number; options: BankOptions[] } = {
              installments: item[0],
              options: [],
            };

            item[1].bankOptions.forEach((item) => {
              if (!usedInterest.find((card) => card === item.card_id)) {
                object.options.push(item);
                usedInterest.push(item.card_id);
              }
            });
            options.push(object);
          }
        });
      }
      break;
  }

  // si configuran la opción de un pago en el backoffice acá es donde la agrego y la sumo al array final.
  const [singleInstallmentOption] = options.filter((option) => option.installments === 1);
  const single = singleInstallmentOption?.options.filter((option) => option.card_id === +card_id);
  let finalArray = options.filter((option) => option.options.length > 0 && option.installments > 1).slice(0, 3);
  if (singlePayOption && singlePayOption !== '-' && single?.length && card_id && +card_id > 0) {
    const thirdOption = finalArray.slice(0, 2);
    thirdOption.push({ installments: 1, options: single });
    finalArray = [...thirdOption];
  }

  return { finalArray };
};
