import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { useField } from 'react-final-form-hooks';
import { sortBy } from 'lodash';
import { CardName, PaymentModalName, CardData, PaymentSimulation, TokenizedCard } from '../../../../../../app/models';
import { getDiscountPublication, getInterest, formatPriceNotDecimal } from '../../../../../../utils';
import { EMITTERS } from '../../../../../../app/const/Emitters';
import { PriceView } from '../../../../../../commons/components/PriceView';
import { useCheckoutFormContext } from '../../../hooks/CheckoutFormContext';
import { useCartContext, useRolesContext } from '../../../../../../context';
import { useSimulateDAO } from '../../../../../../app/business/Simulate';
import { useBreakpointsState } from '../../../../../../app/hooks/useMobile';
import { checkoutAllValues } from '../../../initialValues';
import { useTokenizedCardDAO } from '../../../../../../app/business/TokenizedCard';

export const useGenerateValues = () => {
  const [promotions, setPromotions] = useState<CardData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingTCards, setLoadingTCards] = useState<boolean>(false);
  const [tokenizedCards, setTokenizedCards] = useState<TokenizedCard[]>([]);
  const simulateDAO = useSimulateDAO();
  const { cartState } = useCartContext();
  const { form, values } = useCheckoutFormContext();
  const { userInfo } = useRolesContext();
  const tokenizedCardDAO = useTokenizedCardDAO();
  const { xs } = useBreakpointsState();

  const paymentField = useField('paymentMethod.payment', form);

  const valuesForm = useMemo(() => ({ ...checkoutAllValues, ...values }), [values]);

  useEffect(() => {
    (async () => {
      if (valuesForm.paymentMethod.emitter.name !== '') {
        setLoading(true);
        const { totals, ...rest } = cartState;
        const data: PaymentSimulation = await simulateDAO.generateSimulate(
          rest,
          valuesForm.paymentMethod.emitter.card_emiter_id,
          valuesForm.delivered === 'HOP'
            ? valuesForm.ubicationInfo.state_id
            : valuesForm.deliveryInfo.province.id
            ? valuesForm.deliveryInfo.province.id
            : 0,
        );
        setPromotions(Object.values(data.options));
        setLoading(false);
      }
    })();
  }, [
    cartState,
    simulateDAO,
    valuesForm.delivered,
    valuesForm.deliveryInfo.province.id,
    valuesForm.paymentMethod.emitter.card_emiter_id,
    valuesForm.paymentMethod.emitter.name,
    valuesForm.ubicationInfo.state_id,
  ]);

  const {
    coupon: { amountCoupon },
  } = valuesForm;

  const cards = useMemo<CardName[]>(
    () =>
      promotions.map((item) => {
        const sort = sortBy(item.payments, 'installments').reverse();
        const cuotas = sort[0].installments > 1 ? 'cuotas' : 'cuota';
        const to = sort[0].installments > 1 ? 'Hasta en' : 'En';
        const discount = getDiscountPublication(sort[0].amount.toString(), sort[0].original_amount);
        const interest = discount <= 0 ? getInterest(sort[0].original_amount, sort[0].amount) : 0;
        const textInterest = sort[0].installments > 1 ? (interest === 0 ? `sin interés` : interest !== 0 ? 'fijas' : '') : '';
        const nameOption =
          valuesForm.paymentMethod.emitter.card_emiter_id === EMITTERS.CATAMARCA_CREDITS
            ? item.card.name
            : `${item.card.name}. ${to} ${sort[0].installments} ${cuotas} ${textInterest}: Total $${formatPriceNotDecimal(
                sort[0].amount - amountCoupon,
              )}`;
        return {
          ...item.card,
          nameOption,
        };
      }),
    [promotions, valuesForm.paymentMethod.emitter.card_emiter_id, amountCoupon],
  );

  const payments = useMemo<PaymentModalName[]>(() => {
    if (valuesForm.paymentMethod.card.name !== '' && Number(valuesForm.paymentMethod.card.card_id) > 0) {
      const select = promotions.find((item) => item.card.card_id === valuesForm.paymentMethod.card.card_id);
      const _payments = sortBy(select?.payments, 'installments').reverse();
      return _payments.map((item) => {
        const cuotas = item.installments > 1 ? 'cuotas' : 'cuota';
        const discount = getDiscountPublication(item.amount.toString(), item.original_amount);
        const interest = discount <= 0 ? getInterest(item.original_amount, item.amount) : 0;
        const textInterest = item.installments > 1 ? (interest === 0 ? `sin interés` : interest !== 0 ? 'fijas' : '') : '';
        return {
          ...item,
          id: `${item.installments} ${item.amount} ${item.original_amount}`,
          installments: item.installments,
          name: (
            <PriceView
              variant="caption"
              key={`${item.amount / item.installments}-best-promos`}
              id={`${item.reference}-best-promos`}
              beforeText={`En ${item.installments} ${cuotas} ${textInterest} de`}
              afterText={`${xs ? '' : `; Total $${formatPriceNotDecimal(item.amount - amountCoupon)}`}`}
              withDecimals={item.installments > 1}
              price={((item.amount - amountCoupon) / item.installments).toString()}
              afterProps={item.installments > 1 ? {} : { pl: 0 }}
            />
          ),
        };
      });
    }
    return [] as PaymentModalName[];
  }, [promotions, valuesForm.paymentMethod.card.card_id, valuesForm.paymentMethod.card.name, xs, amountCoupon]);

  const select = promotions.find((item) => item.card.card_id === valuesForm.paymentMethod.card.card_id);

  useEffect(() => {
    if (
      valuesForm.paymentMethod.emitter.card_emiter_id === EMITTERS.CATAMARCA_CREDITS &&
      valuesForm.paymentMethod.card.name !== '' &&
      select &&
      (valuesForm.paymentMethod.payment.amount === 0 || select?.payments[0].reference !== valuesForm.paymentMethod.payment.reference)
    ) {
      const select = promotions.find((item) => item.card.card_id === valuesForm.paymentMethod.card.card_id);
      paymentField.input.onChange(select?.payments[0]);
    }
  }, [
    paymentField.input,
    valuesForm.paymentMethod.emitter.card_emiter_id,
    select,
    valuesForm.paymentMethod.card.name,
    valuesForm.paymentMethod.payment,
    valuesForm.paymentMethod.card.card_id,
    promotions,
  ]);

  const getCards = useCallback(async () => {
    try {
      setLoadingTCards(true);
      const cards: TokenizedCard[] = await tokenizedCardDAO.getAllTokenizedCards();
      if (cards.length) {
        // * Filter cards expired
        setTokenizedCards(cards.filter((item) => !item.expired));
        // setLoadingTCards(false);
      }
      // setLoadingTCards(false);
    } catch (error) {
      console.log('Error to get token cards', error);
    } finally {
      setLoadingTCards(false);
    }
  }, [tokenizedCardDAO]);

  useEffect(() => {
    if (!userInfo.id) return;
    getCards();
  }, [userInfo.id, getCards]);

  return { payments, loading, cards, tokenizedCards, loadingTCards };
};
