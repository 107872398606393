export enum EMITTERS {
  VISA = 1,
  MASTER = 2,
  AMEX = 3,
  BAPRO = 5,
  MERCADOPAGO = 8,
  CATAMARCA_CREDITS = 9,
  NARANJA,
  HIPOTECARIO,
  BNA
}
//!  OJO si eso no coincide no pasara el formulario
//!  Nombres de name devuelto por la lib 'card-validator'
export const EMITTER_VALIDATE: { [k: number]: string } = {
  [EMITTERS.MASTER]: 'mastercard',
  [EMITTERS.VISA]: 'visa',
  [EMITTERS.AMEX]: 'american-express',
};
